import { Button } from "../components/ui/button";
import slugifyLib from "slugify";

const replaceWithMarkup = (str: string, theme = "dark") => {
  const regexStrikethrough = /~(.*?)~/g;
  const regexHighlight = /\*\*(.*?)\*\*/g;
  const regexBold = /\*(.*?)\*/g;
  const regexLink = /\[([^\]]+)]\((\/[^\s)]*|https?:\/\/[^\s)]+)\)/g;

  const highlightColor = theme === "dark" ? "text-blue-400" : "text-100";

  const parts: (string | JSX.Element)[] = [];
  let lastIndex = 0;

  str.replace(regexLink, (match, text, url, offset) => {
    if (lastIndex < offset) {
      parts.push(str.slice(lastIndex, offset));
    }

    const isRelative = url.startsWith("/");

    parts.push(
      <Button
        key={`${text}-${url}`}
        href={url}
        appearance="link"
        className="text-[length:inherit] underline"
        weight="medium"
        newTab={!isRelative}
        color={theme === "dark" ? "blue" : "blueDark"}
      >
        {text}
      </Button>
    );

    lastIndex = offset + match.length;
    return match;
  });

  if (lastIndex < str.length) {
    parts.push(str.slice(lastIndex));
  }

  return parts.flatMap((part, i) => {
    if (typeof part === "string") {
      const subParts = part.split(/\\n/).flatMap((line, index, array) => {
        const lineParts: (string | JSX.Element)[] = [];

        line = line.replace(regexStrikethrough, (_, content) => {
          lineParts.push(
            <s className="line-through" key={`strikethrough-${i}-${index}`}>
              {content}
            </s>
          );
          return "";
        });

        line = line.replace(regexHighlight, (_, content) => {
          lineParts.push(
            <span key={`highlight-${i}-${index}`} className={highlightColor}>
              {content}
            </span>
          );
          return "";
        });

        line = line.replace(regexBold, (_, content) => {
          lineParts.push(<b key={`bold-${i}-${index}`}>{content}</b>);
          return "";
        });

        if (line) lineParts.unshift(line);

        if (index < array.length - 1) {
          return [
            ...lineParts,
            <br key={`br-${i}-${index}`} className="md:block" />,
          ];
        }

        return lineParts;
      });

      return subParts;
    }

    return part;
  });
};

const slugify = (content: string) => {
  return slugifyLib(content, {
    lower: true,
    strict: true,
    replacement: "_",
  });
};

export { replaceWithMarkup, slugify };
